import React, { useState } from 'react';
import "./BreakingNews.css"
import { Link } from 'react-router-dom';


const BreakingNews = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseOver = () => {
    setIsPaused(true);
  };

  const handleMouseOut = () => {
    setIsPaused(false);
  };

  return (
    <div className="container mt-5 brkNews">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center breaking-news bg-white">
            <div className="d-flex flex-row flex-grow-1 flex-fill justify-content-center brNewsBg py-2 text-white px-1 news">
              <span className="d-flex align-items-center"><b>News</b></span>
            </div>
            <marquee
              className="news-scroll"
              behavior="scroll"
              direction="left"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              scrollamount={isPaused ? 0 : 6}
            >

              {/* <Link to='/career'>
                <a><b>JOB Alert: <span className="newsWeight">Senior Web Developer</span>(3) | <span className="newsWeight">Mid-level Web Developer</span>(3)  | <span className="newsWeight">Network Engineer</span>(2)<small> [Details Coming Soon]</small></b></a>
                <span className="dot"></span>
              </Link> */}




              <Link to='/events'>
                <a>
                  <span className="newsWeight">
                    Breaking News: Exclusive University Open Days at our Banani office Announced! 🚨
                    <span className="newsDateColor">
                      🔹 University of Portsmouth - 4th Feb 2025, 10 AM - 5 PM
                      🔹 Canterbury Christ Church University (CCCU) - 8th Feb 2025, 10 AM - 5 PM
                      🔹 Coventry University - 15th Feb 2025, 10 AM - 5 PM
                      🔹 UWE Bristol - 23rd Feb 2025, 10 AM - 5 PM
                    </span>
                    To celebrate, we're hosting a special Open Day for students interested in higher education. 🚀
                  </span>
                  <b>
                    <small>
                      [Click Here for Details]
                    </small>
                  </b>
                </a>
                <span className="dot">

                </span>
              </Link>


              <Link to='/events'>
                <a><b>
                  We Are Organizing Education Expos in <span className="newsWeight">Multiple Countries</span>. Attend and Find <span className="newsDateColor">Your Dream University</span>!
                  <small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link>





              {/* <Link to='/events'>
                <a><b>Your opportunity to consult with <span className="newsDateColor">Mr. Forhadul Alam</span> is now available from <span className="newsWeight">Feb 24- Feb 29</span>, 2024 at our Sylhet office.</b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>Meet <span className="newsWeight">Ms. Neeta Tyagi</span> from <span className="newsDateColor">Queen's University Belfast</span>, a prestigious Russell Group University in the UK. Join us on <span className="newsWeight">March 1st</span><small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>
                  International Education Expo coming to <span className="newsWeight">Sylhet </span> on <span className="newsDateColor">MAY 15th </span> at <span className="newsWeight"> Grand Palace, Jollarpar, Sylhet!!</span>. <small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>
                  International Education Fair coming to <span className="newsWeight">Chattogram </span> on <span className="newsDateColor">MAY 18th </span> at <span className="newsWeight"> The Peninsula Chittagong!!</span>. <small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>
                  📢 Exciting Announcement: Multi-Destination Expo coming to <span className="newsWeight">Dhaka </span> on <span className="newsDateColor">MAY 25th </span> at <span className="newsWeight"> Sheraton Dhaka!</span>. <small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>
                  UK Spot Application Day with <span className="newsDateColor">Mr. Md. Nuruzzaman</span> from the <span className="newsDateColor"> University of Portsmouth</span> on <span className="newsWeight">17th January</span> at our <span className="newsDateColor">Chittagong office.</span><small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              <Link to='/blogs'>
                <a><b>
                  Explore the Latest Posts on Our Blog!<small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link>

              {/* Spot assessment with De Montfort University on 5th September at our Dhaka office. */}

              {/* <Link to='/events'>
                <a><b>
                  Spot assessment session with <span className="newsDateColor">Mr. Liam Stott</span> from the <span className="newsDateColor">University of Bedfordshire</span> on <span className="newsWeight">8th February</span> at our <span className="newsDateColor">Sylhet office.</span><small> [Click Here for Details]</small></b></a><span className="dot"></span>
              </Link> */}

              {/* <Link to='/events'>
                <a><b>

                  Spot assessment day with multiple <span className="newsDateColor">top-ranking universities</span> on <span className="newsWeight">9th</span> of September at our <span className="newsColorDate">Chittagong office.</span> <small> [Click Here for Details]</small>
                </b></a><span className="dot"></span>
              </Link> */}

              <Link to='/photos'>
                <a><b>Representatives from the <span className="newsDateColor">University of Dundee</span>, <span className="newsDateColor">University of Birmingham</span>, and <span className="newsDateColor">Queen's University Belfast</span> visited our Dhaka office for separate spot assessment sessions.<small>[see here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: (1/5) Senior Recruitment Manager - Dhaka <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: (2/5) Senior Recruitment Manager - Chattogram <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: (3/5) Destination Manager Australia - Dhaka <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: (4/5) Destination Manager Canada - Dhaka <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: (5/5) Destination Manager UK - Dhaka <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>
              <Link to='/career'>
                <a><b>JOB Alert: Digital Marketing Executive. <small>[click here]</small></b></a>
                <span className="dot"></span>
              </Link>



            </marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakingNews;














